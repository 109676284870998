import React from "react"
import "../assets/styles/components/hostingLayout.scss"
import AccountHeader from "./accountHeader"

const HostingLayout = ({ children }: any) => {
  return (
    <div className="hosting-layout">
      <div className="first-inner-container">
        <div className="second-inner-container">
          <div className="third-inner-container">
            <AccountHeader />
            <div className="hosting-content">
              <div className="content-wrapper">
                <div style={{ float: "right" }}></div>
                <div style={{ float: "right" }}></div>
                <div className="inner-content-wrapper">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HostingLayout
