import React from "react"
import "../assets/styles/components/hostRadio.scss"

const HostRadio = (props: { [keys: string]: any }) => {
  const { active, name, onClick, value, children } = props

  return (
    <div className="host-radio">
      <div className="inner-radio-wrapper">
        <button
          onClick={onClick}
          name={name}
          value={value}
          className={active ? "radio-selection selected" : "radio-selection"}
        >
          {children}
          <div></div>
        </button>
      </div>
    </div>
  )
}

export default HostRadio
