import React, { useEffect, useRef, useState } from "react"
import HostRadio from "./hostRadio"
import "../assets/styles/components/host.scss"
import AccountInput from "./accountInput"
import { useInputChange } from "../custom-hook/useForm"
import { Link } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { handleHostType } from "../helper/firebase/transaction/profile.trx"
import { Dispatch } from "@reduxjs/toolkit"

const Host = () => {
  const [formInput, setFormInput] = useState({ form: "hostType" })
  const [isNonProfit, setIsNonProfit] = useState(false)
  const [styleX, setStyleX] = useState({
    display: "block",
    height: 40 + "px",
    width: 40 + "px",
    fill: "currentColor"
  })

  const dispatch = useDispatch()
  const userStatus = useSelector((state: any) => state.auth.userStatus)
  const kycProfileStatus = useSelector((state: any) => state.profileKyc.kycProfileStatus)

  const customHandleHostType = handleHostType(dispatch as Dispatch)

  const [isIndividual, setIsIndividual] = useState(false)
  const [isPartnership, setIsPartnership] = useState(false)
  const [isPrivateCompany, setIsPrivateCompany] = useState(false)
  const [isPublicCompany, setIsPublicCompany] = useState(false)
  const [isGovernmentCompany, setIsGovernmentCompany] = useState(false)
  const [charity, setCharity] = useState(false)
  const [input, handleInputChange] = useInputChange()
  const [to, setTo] = useState("")
  const hostTypeRef = useRef("")

  const handleIndividual = () => {
    setIsIndividual(true)
    setIsPartnership(false)
    setIsPrivateCompany(false)
    setIsPublicCompany(false)
    setIsGovernmentCompany(false)
    setCharity(false)
  }

  const handlePartnership = () => {
    setIsIndividual(false)
    setIsPartnership(true)
    setIsPrivateCompany(false)
    setIsPublicCompany(false)
    setIsGovernmentCompany(false)
    setCharity(true)
  }

  const handlePrivateCompany = () => {
    setIsIndividual(false)
    setIsPartnership(false)
    setIsPrivateCompany(true)
    setIsPublicCompany(false)
    setIsGovernmentCompany(false)
    setCharity(true)
  }

  const handlePublicCompany = () => {
    setIsIndividual(false)
    setIsPartnership(false)
    setIsPrivateCompany(false)
    setIsPublicCompany(true)
    setIsGovernmentCompany(false)
    setCharity(true)
  }

  const handleGovernment = () => {
    setIsIndividual(false)
    setIsPartnership(false)
    setIsPrivateCompany(false)
    setIsPublicCompany(false)
    setIsGovernmentCompany(true)
    setCharity(true)
  }

  const handleIsNonProfit = () => {
    setIsNonProfit(!isNonProfit)
    const sameTradingName = { currentTarget: { name: "isNonProfit", value: !isNonProfit } }
    handleInputChange(sameTradingName)
  }

  const inputHostType = input.hostType

  useEffect(() => {
    hostTypeRef.current = input.hostType
    handleRoute(hostTypeRef.current)
    setFormInput({ ...formInput, ...input })
  }, [inputHostType, input])

  const handleRoute = (hostType: string) => {
    switch (hostType) {
      case "individual":
        setTo("/account-details/registered")
        break

      case "partnership":
        setTo("/account-details/registered")
        break

      case "private company":
        setTo("/account-details/private-company-host/your-identity")
        break

      case "public company":
        setTo("/account-details/public-company-host/your-identity")
        break

      case "government-owned company":
        setTo("/account-details/government-company-host/your-identity")
        break

      default:
        break
    }
  }

  return (
    <div className="host">
      <h3 className="h3-heading">Which best describes how you host?</h3>
      <div className="desc-1">
        This helps us ask the right questions in the next few steps.
        <span>
          <button className="learn-btn">Learn more</button>
        </span>
      </div>
      <div className="host-types">
        <HostRadio
          active={isIndividual}
          name="hostType"
          value="individual"
          onClick={(event: any) => {
            handleIndividual()
            handleInputChange(event)
          }}
        >
          <div className="host-type">
            <div className="host-icon">
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={styleX}
              >
                <path d="m16 4c3.8659932 0 7 3.13400675 7 7 0 2.328545-1.1369648 4.3915377-2.8860298 5.6641133 4.8605132 1.6200984 8.4448753 6.0335224 8.8481571 11.3361508h-2.0069465c-.4449707-4.9391434-4.1547367-8.9369249-8.9547652-9.8188115l.0001168-2.5977289c1.7656204-.7716782 2.9994676-2.5336107 2.9994676-4.5837237 0-2.76142375-2.2385763-5-5-5s-5 2.23857625-5 5c0 2.0504975 1.23431 3.8127064 3.0004611 4.5841577l.0001295 2.5971101c-4.8004953.8815089-8.51073725 4.8794728-8.95576555 9.8189963h-2.00695216c.40328479-5.3026284 3.98769876-9.7160538 8.84909651-11.3361748-1.7500046-1.2725516-2.8869694-3.3355443-2.8869694-5.6640893 0-3.86599325 3.1340068-7 7-7z"></path>
              </svg>
            </div>
            <div className="host-title">I host on my own</div>
            <div className="host-desc">One and only one owner</div>
          </div>
        </HostRadio>
        <HostRadio
          active={isPartnership}
          name="hostType"
          value="partnership"
          onClick={(event: any) => {
            handlePartnership()
            handleInputChange(event)
          }}
        >
          <div className="host-type">
            <div className="host-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={styleX}
              >
                <path d="M32,25H30a8.007,8.007,0,0,0-7-7.93054V14.81573a3.00011,3.00011,0,1,0-2-.00006v2.25379a7.958,7.958,0,0,0-3.50024,1.33246A9.95129,9.95129,0,0,1,20,25H18a7.95162,7.95162,0,0,0-2-5.27905,8.057,8.057,0,0,0-1.49969-1.319A7.958,7.958,0,0,0,11,17.06946V14.81573a3.00011,3.00011,0,1,0-2-.00006v2.25379A8.007,8.007,0,0,0,2,25H0a10.01615,10.01615,0,0,1,6.54834-9.38574,5,5,0,1,1,6.90332,0A9.98322,9.98322,0,0,1,16,17.00879a9.98325,9.98325,0,0,1,2.54785-1.39453,5,5,0,1,1,6.9043,0A10.01571,10.01571,0,0,1,32,25Z"></path>
              </svg>
            </div>
            <div className="host-title">Partnership</div>
            <div className="host-desc">Two or more people hosting together</div>
          </div>
        </HostRadio>
        <HostRadio
          active={isPrivateCompany}
          name="hostType"
          value="private company"
          onClick={(event: any) => {
            handlePrivateCompany()
            handleInputChange(event)
          }}
        >
          <div className="host-type">
            <div className="host-icon">
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={styleX}
              >
                <path d="m27 13.46692 2 1.97156v12.56152a2.0026 2.0026 0 0 1 -2 2h-20v-2h20zm-2-1.19153v13.72461h-22v-13.72461a2.0113 2.0113 0 0 1 .59521-1.42334l9.00049-8.87256a2.00918 2.00918 0 0 1 2.80811 0l9.00049 8.87158a2.01371 2.01371 0 0 1 .5957 1.42432zm-9 3.72461h-5v8h5zm7-3.72461-9-8.87109-9 8.87158v11.72412h4v-8a2.002 2.002 0 0 1 2-2h5a2.00229 2.00229 0 0 1 2 2v8h5z"></path>
              </svg>
            </div>
            <div className="host-title">Private company</div>
            <div className="host-desc">An established company that's not publicly listed</div>
          </div>
        </HostRadio>
        <HostRadio
          active={isPublicCompany}
          name="hostType"
          value="public company"
          onClick={(event: any) => {
            handlePublicCompany()
            handleInputChange(event)
          }}
        >
          <div className="host-type">
            <div className="host-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={styleX}
              >
                <path d="M28,2H4A2.002,2.002,0,0,0,2,4V28a2.00229,2.00229,0,0,0,2,2H28a2.0026,2.0026,0,0,0,2-2V4A2.00229,2.00229,0,0,0,28,2Zm0,2V8H19.91406l-2.5-2.5a2.001,2.001,0,0,0-2.82812,0l-2.5,2.5H4V4ZM18,28.00043l-4,.00012V23h4Zm2-.00012V23a2.0026,2.0026,0,0,0-2-2H14a2.00229,2.00229,0,0,0-2,2v5.00067L4,28.001V10h8.91406L16,6.91406,19.08594,10H28V28ZM25,22a1,1,0,1,1-1-1A1,1,0,0,1,25,22Zm0-4a1,1,0,1,1-1-1A1,1,0,0,1,25,18Zm0-4a1,1,0,1,1-1-1A1,1,0,0,1,25,14Zm-8,0a1,1,0,1,1-1-1A1,1,0,0,1,17,14ZM9,22a1,1,0,1,1-1-1A1,1,0,0,1,9,22Zm0-4a1,1,0,1,1-1-1A1,1,0,0,1,9,18Zm0-4a1,1,0,1,1-1-1A1,1,0,0,1,9,14Z"></path>
              </svg>
            </div>
            <div className="host-title">Public company</div>
            <div className="host-desc">A business with shares listed on a public exchange</div>
          </div>
        </HostRadio>
        <HostRadio
          active={isGovernmentCompany}
          name="hostType"
          value="government-owned company"
          onClick={(event: any) => {
            handleGovernment()
            handleInputChange(event)
          }}
        >
          <div className="host-type">
            <div className="host-icon">
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={styleX}
              >
                <path d="m28 7h-8v-3a2.00229 2.00229 0 0 0 -2-2h-14a2.002 2.002 0 0 0 -2 2v24a2.00229 2.00229 0 0 0 2 2h24a2.0026 2.0026 0 0 0 2-2v-19a2.00229 2.00229 0 0 0 -2-2zm-15 21h-4v-5h4zm5 0h-3v-5a2.00229 2.00229 0 0 0 -2-2h-4a2.00229 2.00229 0 0 0 -2 2v5h-3l-.00146-24h14.00146zm10 0h-8v-19h8zm-3-12a1 1 0 1 1 -1-1 1 1 0 0 1 1 1zm-2-4a1 1 0 1 1 1 1 1 1 0 0 1 -1-1zm-8 4a1 1 0 1 1 -1-1 1 1 0 0 1 1 1zm0-4a1 1 0 1 1 -1-1 1 1 0 0 1 1 1zm0-4a1 1 0 1 1 -1-1 1 1 0 0 1 1 1zm-6 8a1 1 0 1 1 -1-1 1 1 0 0 1 1 1zm0-4a1 1 0 1 1 -1-1 1 1 0 0 1 1 1zm0-4a1 1 0 1 1 -1-1 1 1 0 0 1 1 1z"></path>
              </svg>
            </div>
            <div className="host-title">Government-owned company</div>
            <div className="host-desc">Also called a state-owned enterprise</div>
          </div>
        </HostRadio>
      </div>
      <div className="lower-wrapper">
        {charity ? (
          <div className="trust-charity">
            <div className="charity-text">Do you host as a trust or a charity?</div>
            <AccountInput
              name={"isNonProfit"}
              type={"checkbox"}
              onChange={handleIsNonProfit}
              checked={isNonProfit}
              extraText={"Yes, it’s a trust or a charity"}
            />
          </div>
        ) : null}

        <div className="button-wrapper">
          {/* <button className="next-btn" type="button">
            Next
          </button> */}

          {!hostTypeRef.current ? (
            <button type="submit" className="next-btn">
              Next
            </button>
          ) : (
            <Link to={to}>
              <button
                type="submit"
                onClick={() => customHandleHostType(kycProfileStatus, formInput, userStatus)}
                className="next-btn"
              >
                Next
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default Host
